import { GetServerSidePropsContext } from "next";
import { useTranslations } from "use-intl";

import { PageHead } from "@/components/common";
import { PageMap } from "@/components/home";
import { MainLayout } from "@/components/layout";
import { HomePageApi, THomePageData } from "@/shared/api/homepage-api";
import { ShopCategorie, ShopItem, ShopsApi } from "@/shared/api/shops-api";
import { ShopsContext } from "@/shared/hooks/use-shops-data";
import { mapSEOTags } from "@/shared/lib";
import { ComponentType } from "@/shared/types";

type PageHomeProps = {
  messages: unknown;
  pageData: THomePageData;
  shopsList: ShopItem[];
  shopsCategories: ShopCategorie[];
};

const PageHome = (props: PageHomeProps) => {
  const { pageData, shopsList, shopsCategories } = props;
  const seoTagsData = mapSEOTags(pageData?.attributes?.seo);

  const t = useTranslations();

  const pageContent = pageData?.attributes?.content;

  return (
    <ShopsContext.Provider value={{ shopsList, shopsCategories }}>
      <PageHead title={t("nav.home")} seo={seoTagsData} />
      <MainLayout>
        {pageContent?.map((content, i) => (
          <PageMap component={content} key={i} />
        ))}
      </MainLayout>
    </ShopsContext.Provider>
  );
};

export default PageHome;

export const getServerSideProps = async ({
  locale,
}: GetServerSidePropsContext) => {
  const api = new HomePageApi(locale || "en");
  const shopsApi = new ShopsApi(locale || "en");
  const pageData = await api.getHomepageData();
  const shopsList = await shopsApi.getShops();
  const shopsCategories = await shopsApi.getShopCategories();

  if (pageData?.attributes?.content) {
    const shopsCategoriesContentIndex = pageData?.attributes?.content.findIndex(
      (item) => item.__component === ComponentType.SHOP_CATEGORIES
    );
    if (shopsCategoriesContentIndex) {
      const shopCategoriesBlock = pageData.attributes.content[
        shopsCategoriesContentIndex
      ] as any;

      if (shopCategoriesBlock?.shops) {
        shopCategoriesBlock.shops = shopsList;
      }
    }
  }

  return {
    props: {
      messages: require(`../shared/lib/i18n/locales/${locale}.json`),
      pageData,
      shopsList,
      shopsCategories,
    },
  };
};
